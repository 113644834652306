import React from "react"
import { Provider } from "react-redux"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"
import storage from "redux-persist/lib/storage"
import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import rootReducer from "."
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1"
import { ToastContainer, toast } from "react-toastify"

const persistConfig = {
  key: "doacaouniversal",
  storage,
  stateReconciler: autoMergeLevel1,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = reduxCreateStore(persistedReducer, applyMiddleware(thunk))
export const persistor = persistStore(store)

export default ({ element }) => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <PersistGate persistor={persistor}>{element}</PersistGate>
    </Provider>
  )
}
