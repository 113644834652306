// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cadastro-sms-js": () => import("./../../../src/pages/cadastroSMS.js" /* webpackChunkName: "component---src-pages-cadastro-sms-js" */),
  "component---src-pages-cartao-sms-js": () => import("./../../../src/pages/cartaoSMS.js" /* webpackChunkName: "component---src-pages-cartao-sms-js" */),
  "component---src-pages-confirmacao-sms-js": () => import("./../../../src/pages/confirmacaoSMS.js" /* webpackChunkName: "component---src-pages-confirmacao-sms-js" */),
  "component---src-pages-doar-index-js": () => import("./../../../src/pages/doar/index.js" /* webpackChunkName: "component---src-pages-doar-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-perfil-index-js": () => import("./../../../src/pages/perfil/index.js" /* webpackChunkName: "component---src-pages-perfil-index-js" */),
  "component---src-templates-contas-js": () => import("./../../../src/templates/contas.js" /* webpackChunkName: "component---src-templates-contas-js" */),
  "component---src-templates-doar-confirmacao-pix-js": () => import("./../../../src/templates/doar/confirmacaoPix.js" /* webpackChunkName: "component---src-templates-doar-confirmacao-pix-js" */),
  "component---src-templates-home-pix-js": () => import("./../../../src/templates/home/pix.js" /* webpackChunkName: "component---src-templates-home-pix-js" */),
  "component---src-templates-home-pix-mobile-js": () => import("./../../../src/templates/home/pix-mobile.js" /* webpackChunkName: "component---src-templates-home-pix-mobile-js" */),
  "component---src-templates-home-sms-js": () => import("./../../../src/templates/home/sms.js" /* webpackChunkName: "component---src-templates-home-sms-js" */),
  "component---src-templates-igrejas-js": () => import("./../../../src/templates/igrejas.js" /* webpackChunkName: "component---src-templates-igrejas-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-materiais-alimento-js": () => import("./../../../src/templates/materiais-alimento.js" /* webpackChunkName: "component---src-templates-materiais-alimento-js" */),
  "component---src-templates-obras-sociais-js": () => import("./../../../src/templates/obras-sociais.js" /* webpackChunkName: "component---src-templates-obras-sociais-js" */),
  "component---src-templates-pix-tutorial-index-js": () => import("./../../../src/templates/pix-tutorial/index.js" /* webpackChunkName: "component---src-templates-pix-tutorial-index-js" */)
}

