//Wizard
const SET_STEP = "SET_STEP"

//Donation
const GET_CHURCHS = "GET_CHURCHS"
const GET_CHURCHS_SUCCESS = "GET_CHURCHS_SUCCESS"
const GET_CHURCHS_ERROR = "GET_CHURCHS_ERROR"
const GET_WORKS = "GET_WORKS"
const GET_WORKS_SUCCESS = "GET_WORKS_SUCCESS"
const GET_WORKS_ERROR = "GET_WORKS_ERROR"
const SET_DESTINATION = "SET_DESTINATION"
const SET_CAMPAIGN = "SET_CAMPAIGN"
const SET_CONFIRMATION = "SET_CONFIRMATION"
const GET_ALL_DONATIONS = "GET_ALL_DONATIONS"
const GET_RECURRENT_DONATIONS = "GET_RECURRENT_DONATIONS"
const SET_DONATION_STATUS = "SET_DONATION_STATUS"
const SET_FORM_OF_PAYMENT = "SET_FORM_OF_PAYMENT"
const SET_PIX_DATA = "SET_PIX_DATA"

//Auth
const CHECK_PHONE = "CHECK_PHONE"
const CREATE_USER = "CREATE_USER"
const SET_TOKEN = "SET_TOKEN"
const UPDATE_COORDINATES = "UPDATE_COORDINATES"
const SET_CREDIT_CARD = "SET_CREDIT_CARD"
const SET_ADDRESS = "SET_ADDRESS"
const LOGOUT = "LOGOUT"
const SET_USER_DATA = "SET_USER_DATA"
const SET_MAIN_CAMPAIGN = "SET_MAIN_CAMPAIGN"

//Church
const GET_CHURCH_LIST = "GET_CHURCH_LIST"

//Campaign
const SET_CHURCH_CAMPAIGN = "SET_CHURCH_CAMPAIGN"

export {
  SET_STEP,
  GET_CHURCHS,
  GET_CHURCHS_SUCCESS,
  GET_CHURCHS_ERROR,
  GET_WORKS,
  GET_WORKS_SUCCESS,
  GET_WORKS_ERROR,
  SET_DESTINATION,
  SET_CAMPAIGN,
  SET_CONFIRMATION,
  GET_ALL_DONATIONS,
  CHECK_PHONE,
  CREATE_USER,
  SET_TOKEN,
  UPDATE_COORDINATES,
  SET_CREDIT_CARD,
  SET_ADDRESS,
  LOGOUT,
  SET_USER_DATA,
  GET_CHURCH_LIST,
  SET_CHURCH_CAMPAIGN,
  SET_MAIN_CAMPAIGN,
  SET_DONATION_STATUS,
  SET_FORM_OF_PAYMENT,
  SET_PIX_DATA,
  GET_RECURRENT_DONATIONS
}
